<template>
  <div>
    <b-overlay :show="isLoading" spinner-variant="primary">
      <div class="row">
        <div class="col-12">
          <h1>Заяви та звернення</h1>
          <p class="mb-1">
            Вхідні заяви та звернення від жителів. <br />Працівник бачить лише
            заявки до відділу, до якого він належить
          </p>
          <div class="mb-2 row">
            <div class="col-8 col-md-9">
              <v-select
                id="select-status"
                v-model="selectedStatus"
                :options="statuses"
                label="text"
                placeholder="Оберіть статуси"
                multiple
              />
              <div class="py-1">
                <b-form-checkbox
                  id="show-published"
                  v-model="show_published"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  Показати опубліковані
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-4 col-md-3">
              <b-button
                @click="getTickets(true)"
                variant="primary"
                class="w-100"
              >
                Фільтрувати
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <ticketItem v-for="t in all_tickets" :key="t.id" :t="t" />
          <b-alert
            variant="warning"
            class="p-2"
            :show="!isLoading && dataLoaded && !all_tickets.length"
          >
            Немає звернень
          </b-alert>
        </div>

        <div class="col-12 d-flex my-2 justify-content-center">
          <b-button v-if="last_doc" variant="primary" @click="getTickets">
            Завантажити ще {{ per_page }}
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ticketItem from "@/components/ticket-list-item.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ticketItem,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      selectedStatus: [],
      defaultStatus: {
        admin: [{ text: "Новий", value: 0 }],
        worker: [
          { text: "Новий", value: 0 },
          { text: "Передано", value: 3 },
        ],
      },
      show_published: false,
      ticket_types: [],
      departments: [],
      all_tickets: [],
      page: 1,
      per_page: 24,
      last_doc: null,
      dataLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
    }),
    statuses() {
      let statuses = this.datasets.statuses;
      let options = statuses.map((x) => ({
        text: x.name,
        value: x.id,
      }));
      return options;
    },
  },
  methods: {
    async getTicketsWorker(filter) {
      if (filter == true) {
        this.all_tickets = [];
      }
      if (this.selectedStatus.length == 0) {
        return;
      }
      this.isLoading = true;
      let workerDepartment = this.$store.state.workerDepartment;
      const statuses = this.selectedStatus.map((x) => Number(x.value));
      var args = {
        collectionName: "tickets",
        where: ["department", "==", workerDepartment],
        where2: ["status", "in", statuses],
        order: ["created_at", "desc"],
        limitBy: Number(this.per_page),
      };
      if (this.show_published) {
        args.where3 = ["make_public", "==", true];
      }
      if (this.page !== 1 && this.last_doc) {
        args["startAfter"] = this.last_doc;
      }
      var result = await this.$db.getCollection(args);
      result.docs.forEach((doc) => {
        this.all_tickets.push(doc);
      });
      if (result.docs.length == this.per_page) {
        this.last_doc = result.lastVisible;
        this.page++;
      } else {
        this.last_doc = null;
      }
      this.isLoading = false;
    },
    async getTicketsAdmin(filter) {
      console.log("admin");
      if (filter == true) {
        this.all_tickets = [];
      }
      if (this.selectedStatus.length == 0) {
        return;
      }
      this.isLoading = true;
      const statuses = this.selectedStatus.map((x) => Number(x.value));
      var args = {
        collectionName: "tickets",
        order: ["created_at", "desc"],
        where: ["status", "in", statuses],
        limitBy: Number(this.per_page),
      };
      if (this.show_published) {
        args.where3 = ["make_public", "==", true];
      }
      if (this.page !== 1 && this.last_doc) {
        args["startAfter"] = this.last_doc;
      }
      var result = await this.$db.getCollection(args);
      result.docs.forEach((doc) => {
        this.all_tickets.push(doc);
      });
      if (result.docs.length == this.per_page) {
        this.last_doc = result.lastVisible;
        this.page++;
      } else {
        this.last_doc = null;
      }

      this.isLoading = false;
    },
    async getTickets(filter) {
      let doFilter = false;
      if (filter == true) {
        doFilter = true;
      }
      if (this.$acl.check("admin")) {
        await this.getTicketsAdmin(doFilter);
      } else if (this.$acl.check("worker")) {
        await this.getTicketsWorker(doFilter);
      }
      this.dataLoaded = true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$acl.check("worker")) {
        this.selectedStatus = this.defaultStatus.worker;
      }
      if (this.$acl.check("admin")) {
        this.selectedStatus = this.defaultStatus.admin;
      }
      this.getTickets(false);
    });
    this.getTickets(false);
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
.search {
  padding-bottom: 20px;
  input {
    height: 35px;
    width: 280px;

    &::placeholder {
      padding-left: 6px;
    }
  }
  .dropdown.b-dropdown {
    height: 33px;
    margin-left: 10px;
  }
}
.user_info {
  margin-top: 20px;
  .user {
    .b-avatar {
      margin-right: 10px;
    }
  }
}
.text {
  margin-top: 20px;
}
</style>
