<template>
  <b-card>
    <div class="ticket__head d-flex justify-content-between">
      <router-link :to="{ name: 'ticket-single', params: { id: t.id } }">
        <h3 v-if="t.type">
          {{ getType(t.type) }}
          № {{ t.id }}
        </h3>
      </router-link>
      <h6>
        <statusBadge :status="t.status" />
      </h6>
    </div>
    <div class="mb-0" v-if="t.topic">
      Тема: <strong>{{ t.topic }}</strong>
    </div>
    <div>
      <div class="sender mb-1" v-if="t.department">
        до
        <router-link
          :to="{
            name: 'departments-single',
            params: { id: getDepartment(t.department).id },
          }"
        >
          {{ getDepartment(t.department).name }}
        </router-link>
      </div>
    </div>
    <hr />
    <div class="ticket__meta d-flex justify-content-end align-items-center">
      <div class="left mr-auto d-flex align-items-center">
        <b-badge variant="light-primary mr-1">№ {{ t.id }}</b-badge>
        <template v-if="t.sender_info && t.sender_info.full_name">
          від &nbsp;
          <router-link
            :to="{ name: 'user-single', params: { id: t.sender_info.id } }"
            >{{ t.sender_info.full_name }}
          </router-link></template
        >
      </div>
      <div class="date mr-2">
        <strong>
          {{ t.created_at.seconds | moment("calendar") }}
        </strong>
      </div>

      <b-button
        variant="outline-primary"
        size="sm"
        :to="{ name: 'ticket-single', params: { id: t.id } }"
      >
        Проглянути
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import statusBadge from "@/components/ticket-status-badge";

export default {
  components: {
    statusBadge,
  },
  props: ["t"],
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
    }),
    statuses() {
      return this.datasets.statuses;
    },
    ticket_types() {
      return this.datasets.ticket_types;
    },
    departments() {
      return this.datasets.departments;
    },
  },
  methods: {
    getStatus(id) {
      var status = this.statuses.find((el) => el.id == id);
      return status.name;
    },
    getType(slug) {
      var type = slug;
      if (this.ticket_types) {
        var found = this.ticket_types.find((el) => el.slug.trim() == slug);
        if (found) {
          type = found.name;
        }
      }

      return type;
    },
    getDepartment(id) {
      var department = this.departments.find((el) => el.id == id);
      return department;
    },
  },
};
</script>

<style lang="scss" scoped></style>
